import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  retry,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "@/redux/Root/store";
import { baseUrl } from "./constants";
import {
  BaseQueryApi,
  BaseQueryFn,
  QueryReturnValue,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { Router, useRouter } from "next/router";
import { logOut } from "@/redux/Authentication/AuthReducer";
import axios, {
  AxiosInstance,
  AxiosInterceptorOptions,
  AxiosResponse,
} from "axios";
import type { AxiosRequestConfig, AxiosError } from "axios";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Cookies } from "react-cookie";
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token.access;
    const cookies = new Cookies();
    if (cookies.get("theme")) {
      console.log(cookies.get("theme"), "poo");
    }
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryInterceptor = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await baseQuery(args, api, extraOptions);
  const status = result?.meta?.response?.status;
  if (status) {
    if (status === 401) {
      api.dispatch(logOut());
    } else if (status < 500) {
      return result;
    }
  }
  return { data: "error" };
};

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiKit = createApi({
  reducerPath: "apiKit",
  baseQuery: baseQueryInterceptor,
  tagTypes: ["shop_tier", "shop_segment"],
  endpoints: (builder) => ({}),
});

export const enhancedApi = apiKit.enhanceEndpoints({
  endpoints: () => ({}),
});

export const ApiKitAxios = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
});

// validateStatus: (status: any) => {
//   console.log(status, "STATUS");
//   if (status == 401) {
//     return (window.location.href = "/auth/login"); // Resolve only if the status code is less than 500
//   } else {
//     return;
//   }
// },
