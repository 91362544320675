import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
import { baseUrl } from "../../base_config/constants";
import { Cookies } from "react-cookie";
import { ApiKitAxios } from "base_config/apiKit";
import { Shop } from "./models";

var cookies = new Cookies();
export interface BaseConfig {
  token: string;
}

export interface CountryProps {
  countries: any;
  loading: boolean;
}

interface CountryThunk {
  status: string;
  payload: {};
}

// api thunk calling

export const getCountry: any = createAsyncThunk<CountryThunk | void>(
  "base/countries",
  async () => {
    return ApiKitAxios.get("core/country/").then((res) => res.data);
  }
);

export const getWebPing: any = createAsyncThunk<CountryThunk | string>(
  "base/web_ping",
  async (token) => {
    return ApiKitAxios.get("auth/web_ping/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((res) => res.data);
  }
);

const CountryInitState: CountryProps = {
  countries: [],
  loading: false,
};

export const CountrySlice = createSlice({
  name: "countries",
  initialState: CountryInitState,
  reducers: {},
  extraReducers: {
    [getCountry.pending]: (state, action) => {
      (state.loading = true), (state.countries = []);
    },
    [getCountry.fulfilled]: (state, action) => {
      (state.loading = true), (state.countries = action.payload);
    },
    [getCountry.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export interface WebPingProps {
  user: any;
  shops: string[];
  loading: boolean;
  active_shop: null | Shop;
}

const WebInitState: WebPingProps = {
  user: null,
  shops: [],
  loading: false,
  active_shop: null,
};

export const WebPingSlice = createSlice({
  name: "web_png",
  initialState: WebInitState,
  reducers: {
    updateShop: (
      state: { active_shop: Shop | null },
      action: PayloadAction<Shop>
    ) => {
      state.active_shop = action.payload;
      cookies.set("active_shop", action.payload.id, {
        path: "/",
      });
    },
  },
  extraReducers: {
    [getWebPing.pending]: (state, action) => {
      (state.loading = true), (state.user = null);
    },
    [getWebPing.fulfilled]: (state, action) => {
      (state.loading = true), (state.user = action.payload.data.count);
      state.shops = action.payload.data.shops;

      state.loading = false;
    },
    [getWebPing.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const theme = {
  mode: "light",
};

export const Theme = createSlice({
  name: "theme",
  initialState: theme,
  reducers: {
    updateTheme: (state: { mode: string }, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const { updateTheme } = Theme.actions;
export const { updateShop } = WebPingSlice.actions;

export default combineReducers({
  ThemeReducer: Theme.reducer,
  CountryReducer: CountrySlice.reducer,
  WebPingReducer: WebPingSlice.reducer,
});
