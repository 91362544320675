import React from "react";
import "../styles/globals.scss";
// import "../styles/theme.scss";
import { Cookies, useCookies } from "react-cookie";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { store } from "../redux/Root/store";
import { Provider } from "react-redux";
import "@szhsin/react-menu/dist/core.css";
import { ErrorBoundary } from "@sentry/nextjs";
import Head from "next/head";
import { useDispatch } from "react-redux";
import { updateTheme } from "@/redux/Base/base";
import toast, { Toaster } from "react-hot-toast";

const BaseProvider = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    var element = document.documentElement;
    var scheme = "(prefers-color-scheme: dark)";
    var system = window.matchMedia(scheme);
    var theme = localStorage.getItem("theme");

    if (theme && theme == "system") {
      if (system.media !== scheme || system.matches) {
        element.setAttribute("data-theme", "dark");
        dispatch(updateTheme("dark"));
      }
      element.setAttribute("data-theme", "light");
      dispatch(updateTheme("light"));
    } else if (theme) {
      element.setAttribute("data-theme", `${theme}`);
      dispatch(updateTheme(theme));
    }
  }, [dispatch]);
  return <></>;
};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Provider store={store}>
        <ErrorBoundary
          fallback={
            <div className="errorBoundary">
              Client side error, We will working to resolve the issue.
            </div>
          }
        >
          <Toaster toastOptions={{ duration: 4000 }} />
          <BaseProvider />
          <Component {...pageProps} />
        </ErrorBoundary>
      </Provider>
    </>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log(metric, "--performance");
}

export default MyApp;
