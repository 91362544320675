import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../Authentication/AuthReducer";
import BaseReducer from "../Base/base";
import Customer from "../Customer/CustomerReducer";
import { apiKit } from "base_config/apiKit";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    base: BaseReducer,
    customer: Customer,
    [apiKit.reducerPath]: apiKit.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiKit.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
