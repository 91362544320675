import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import Router, { useRouter } from "next/router";
import { Cookies } from "react-cookie";
import { baseUrl } from "../../base_config/constants";
import { Shops, Token, User } from "./models";

export interface AuthState {
  token: Token;
  user: User;
}

const initialState: AuthState = {
  token: {
    access: null,
    refresh: null,
  },
  user: {
    name: "",
    mobile: null,
    email: "",
    country: "",
  },
};

const cookies = new Cookies();

export const Authentication = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateCredentials: (
      state: { token: Token },
      action: PayloadAction<any>
    ) => {
      state.token.access = action.payload.access;
      state.token.refresh = action.payload.refresh;

      cookies.set("access_token", action.payload.access, { path: "/" });
      cookies.set("refresh_token", action.payload.refresh, { path: "/" });
    },
    updateUser: (state: { user: User }, action: PayloadAction<User>) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },

    logOut: (state) => {
      state.token.access = null;
      state.token.refresh = null;
      localStorage.clear();
      cookies.remove("access_token");
      cookies.remove("refresh_token");
      Router.push("/auth/login");
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  logOut,
  updateCredentials: updateCredentials,
  updateUser,
} = Authentication.actions;

export default Authentication.reducer;
