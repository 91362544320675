import { createSlice } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";

const cookie = new Cookies();

interface CustomerType {}

const initialState: CustomerType = {
  activeShop: cookie.get("active_shop"),
};

export const Customer = createSlice({
  name: "customer",
  initialState,
  reducers: {},
});

export default Customer.reducer;
